<mat-toolbar class="sidenav-menu-toolbar">
    <a href="/" target="_blank">
        <img mat-card-image ngSrc="../../../../assets/images/logo/derenda-logo.svg"
             alt="DerendaApp Logo" title="DerendaApp Logo"
             height="50" width="150" priority=""></a>
</mat-toolbar>
<mat-divider></mat-divider>
<mat-nav-list>
    <a mat-list-item [routerLinkActive]="['is-active']" routerLink="dashboard"
       (click)="mobileQuery.matches && sidenav.toggle()">
        <div class="mat-list-item-name">
            <mat-icon class="mat-list-item-name__icon" color="primary">dashboard</mat-icon>
            Panel
        </div>
    </a>
    <mat-expansion-panel [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
            <mat-icon color="primary" class="me-2">list_alt</mat-icon>
            Zgłoszenia serwisowe
        </mat-expansion-panel-header>
        <a *ngIf="user.type === 'user'" mat-list-item [routerLinkActive]="['is-active']" routerLink="service-order-add"
           (click)="mobileQuery.matches && sidenav.toggle()">
            <div class="mat-list-item-name">
                <mat-icon class="mat-list-item-name__icon" color="primary">playlist_add</mat-icon>
                Dodanie
            </div>
        </a>
        <a mat-list-item [routerLinkActive]="['is-active']" routerLink="service-orders"
           (click)="mobileQuery.matches && sidenav.toggle()">
            <div class="mat-list-item-name">
                <mat-icon class="mat-list-item-name__icon" color="primary">format_list_bulleted</mat-icon>
                Bieżące
            </div>
        </a>
        <a mat-list-item [routerLinkActive]="['is-active']" routerLink="service-orders-archive"
           (click)="mobileQuery.matches && sidenav.toggle()">
            <div class="mat-list-item-name">
                <mat-icon class="mat-list-item-name__icon" color="primary">checklist</mat-icon>
                Archiwalne
            </div>
        </a>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="user.type === 'member'" [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
            <mat-icon color="primary" class="me-2">access_time</mat-icon>
            Czas pracy
        </mat-expansion-panel-header>
        <a mat-list-item [routerLinkActive]="['is-active']" routerLink="working-time-add"
           (click)="mobileQuery.matches && sidenav.toggle()">
            <div class="mat-list-item-name">
                <mat-icon class="mat-list-item-name__icon" color="primary">more_time</mat-icon>
                Dodanie
            </div>
        </a>
        <a mat-list-item [routerLinkActive]="['is-active']" routerLink="working-times"
           (click)="mobileQuery.matches && sidenav.toggle()">
            <div class="mat-list-item-name">
                <mat-icon class="mat-list-item-name__icon" color="primary">history</mat-icon>
                Lista
            </div>
        </a>
    </mat-expansion-panel>
    <a *ngIf="user.type !== 'user'" mat-list-item [routerLinkActive]="['is-active']" routerLink="intercom-codes"
       (click)="mobileQuery.matches && sidenav.toggle()">
        <div class="mat-list-item-name">
            <mat-icon class="mat-list-item-name__icon" color="primary">keyboard</mat-icon>
            Kody domofonów
        </div>
    </a>
    <a *ngIf="user.type === 'admin'" mat-list-item [routerLinkActive]="['is-active']" routerLink="working-times"
       (click)="mobileQuery.matches && sidenav.toggle()">
        <div class="mat-list-item-name">
            <mat-icon class="mat-list-item-name__icon" color="primary">history</mat-icon>
            Czas pracy
        </div>
    </a>
    <a *ngIf="user.type === 'admin'" mat-list-item [routerLinkActive]="['is-active']" routerLink="users"
       (click)="mobileQuery.matches && sidenav.toggle()">
        <div class="mat-list-item-name">
            <mat-icon class="mat-list-item-name__icon" color="primary">supervisor_account</mat-icon>
            Użytkownicy
        </div>
    </a>
    <mat-expansion-panel *ngIf="user.type === 'admin'" [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
            <mat-icon color="primary" class="me-2">price_change</mat-icon>
            Depozyty
        </mat-expansion-panel-header>
        <a mat-list-item [routerLinkActive]="['is-active']" routerLink="deposit-add"
           (click)="mobileQuery.matches && sidenav.toggle()">
            <div class="mat-list-item-name">
                <mat-icon class="mat-list-item-name__icon" color="primary">playlist_add</mat-icon>
                Dodanie
            </div>
        </a>
        <a mat-list-item [routerLinkActive]="['is-active']" routerLink="deposits"
           (click)="mobileQuery.matches && sidenav.toggle()">
            <div class="mat-list-item-name">
                <mat-icon class="mat-list-item-name__icon" color="primary">format_list_bulleted</mat-icon>
                Bieżące
            </div>
        </a>
        <a mat-list-item [routerLinkActive]="['is-active']" routerLink="deposits-archive"
           (click)="mobileQuery.matches && sidenav.toggle()">
            <div class="mat-list-item-name">
                <mat-icon class="mat-list-item-name__icon" color="primary">checklist</mat-icon>
                Archiwalne
            </div>
        </a>
    </mat-expansion-panel>
    <a mat-list-item [routerLinkActive]="['is-active']" routerLink="profile"
       (click)="mobileQuery.matches && sidenav.toggle()">
        <div class="mat-list-item-name">
            <mat-icon class="mat-list-item-name__icon" color="primary">person</mat-icon>
            Profil
        </div>
    </a>
    <mat-divider></mat-divider>
    <mat-action-list>
        <button mat-list-item (click)="logoutEmit()">
            <span class="mat-list-item-name">
                <mat-icon class="mat-list-item-name__icon" color="primary">logout</mat-icon>
                Wyloguj
            </span>
        </button>
    </mat-action-list>
    <div class="sidenav-footer">
        <app-company-link></app-company-link>
    </div>
</mat-nav-list>
