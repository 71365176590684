<form [formGroup]="commentForm">
    <mat-form-field class="full-width mt-2" appearance="outline">
        <mat-label>Komentarz:</mat-label>
        <textarea matInput formControlName="comment" name="comment" type="text"
                  placeholder="Message" #commentMessage></textarea>
        <mat-hint align="end">
            {{ commentMessage.value.length }} / {{ commentMaxLength }}
        </mat-hint>
        <mat-error *ngIf="comment.errors?.['required']">
            Napisz komentarz.
        </mat-error>
        <mat-error *ngIf="comment.value.length < commentMinLength && !comment.errors?.['required']">
            Minimalna długość wynosi {{ commentMinLength }} znaków.
        </mat-error>
        <mat-error *ngIf="comment.value.length > commentMaxLength">
            Maksymalna długość wynosi {{ commentMaxLength }} znaków.
        </mat-error>
    </mat-form-field>
</form>
