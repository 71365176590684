<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row">
        <div class="col-md-6">
            <mat-card class="bg-white mat-elevation-z3">
                <div class="row">
                    <div class="col-md-6">
                        <mat-card-header>
                            <mat-card-title>
                                <mat-icon class="mx-1" color="primary">more_time</mat-icon>
                                Aktualizacja czasu pracy
                            </mat-card-title>
                            <mat-card-subtitle>Podaj dzień i godzinę zakończenia pracy.</mat-card-subtitle>
                        </mat-card-header>
                    </div>
                </div>
                <mat-card-content>
                    <div *ngIf="workingTime" class="py-4">
                        <h4><i class="fa-solid fa-calendar-days text-primary-color"></i> Data rozpoczęcia: <b class="text-primary-color">{{ workingTime.start | date:'dd/MM/y' }}</b></h4>
                        <h4 class="pt-1"><i class="fa-regular fa-clock text-primary-color"></i> Godzina: <b
                            class="text-primary-color">{{ workingTime.start | date:'HH:mm' }}</b></h4>
                    </div>
                    <h4><i class="fa-solid fa-calendar-days text-primary-color"></i> Data zakończenia:</h4>
                    <app-working-time-form [workingTime]="workingTime" [endMode]="true"
                                           #data></app-working-time-form>
                    <div class="pt-2">
                        <app-working-time-save-button
                            [invalid]="data.workingTimeForm.invalid"
                            (closeForm)="closeForm()"
                            (save)="updateWorkingTime()">
                        </app-working-time-save-button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
