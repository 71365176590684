<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row">
        <div *ngIf="isLoading; else content">
            <div class="d-flex justify-content-center mt-5">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <ng-template #content>
            <div class="container">
                <div class="pb-4">
                    <button class="mx-2" mat-stroked-button (click)="back()">
                        <i class="fa-solid fa-rotate-left"></i> Zamknij
                    </button>
                    <ng-container *ngIf="authService.user.type === 'admin' || authService.user.type === 'member'">
                        <button *ngIf="!isArchive" mat-stroked-button (click)="addImages()">
                            <i class="fa-solid fa-plus"></i> Dodaj
                        </button>
                    </ng-container>
                </div>
                <div class="py-2">
                    <h2>Numer zgłoszenia: <b class="text-primary-color"> {{ serviceOrderID }}</b></h2>
                </div>
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                    <ng-container *ngFor='let image of serviceOrderImagesURL; index as i'>
                        <app-image-card [imageURL]="image.imageURL" [imageID]="image.imageID" [isArchive]="isArchive"
                                        [userType]="authService.user.type" [imageShow]="image.imageShow" (imageView)="imageView($event)"
                                        (imageRemove)="imageRemove($event)" (imageShowUser)="changeImageUserShow($event)">
                        </app-image-card>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </div>
</div>
