<mat-card class="bg-white mt-5 mat-elevation-z3">
    <mat-card-header>
        <mat-card-title><i class="fa-solid fa-file-signature text-primary-color"></i>
            Komentarz:
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ng-container *ngIf="!editMode else commentEdit">
            <ul class="no-bullets mt-2">
                <li>{{ comment }}</li>
            </ul>
        </ng-container>
        <ng-template #commentEdit>
            <div class="py-2">
                <app-comment-form [editMode]="editMode" [commentToEdit]="comment" #data></app-comment-form>
            </div>
            <app-save-button [invalid]="data.commentForm.invalid" (closeForm)="switchEditComment()"
                             (save)="updateComment()"></app-save-button>
        </ng-template>
        <div *ngIf="!editMode" class="d-flex justify-content-end">
            <button class="mx-4" mat-stroked-button (click)="switchEditComment()">
                <i class="fa-solid fa-pen-to-square"></i> Edycja
            </button>
        </div>
    </mat-card-content>
</mat-card>
