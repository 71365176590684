<ng-container *ngFor="let meter of waterMeters; index as i">
    <h4><i class="fa-solid fa-stopwatch text-primary-color"></i> Licznik wody:</h4>
    <ul class="no-bullets">
        <ng-container *ngIf="meter.type === 'hot' else cold">
            <li><i class="fa-solid fa-mug-hot text-danger"></i></li>
        </ng-container>
        <ng-template #cold>
            <li><i class="fa-solid fa-snowflake text-primary"></i></li>
        </ng-template>
        <li>typ licznika: <b class="text-primary-color">{{ meter.type }}</b></li>
        <li>numer: <b class="text-primary-color">{{ meter.number }}</b></li>
        <li>stan licznika: <b class="text-primary-color">{{ meter.counter }}</b></li>
        <li>numer plomby radiowej: <b
            class="text-primary-color">{{ meter.radio_overlay_number }}</b></li>
        <div *ngIf="deleteMode" class="d-flex justify-content-center">
            <button mat-mini-fab color="warn" (click)="deleteEmit(i)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <div *ngIf="editMode" class="d-flex justify-content-end pt-2 pt-md-0">
            <button class="mx-4" mat-stroked-button (click)="editEmit(meter.id)">
                <i class="fa-solid fa-pen-to-square"></i> Edytuj
            </button>
        </div>
    </ul>
</ng-container>
