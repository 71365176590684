<div class="pt-2">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center py-2">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <form [formGroup]="userNameForm">
            <mat-form-field class="three-quarters-width" appearance="outline">
                <mat-label>Imię lub pseudonim</mat-label>
                <input matInput formControlName="name" name="name" type="text" placeholder="Imię lub pseudonim"
                       #displayName>
                <mat-hint align="end">
                    {{ displayName.value.length }} / {{ userNameMaxLength }}
                </mat-hint>
                <mat-error *ngIf="name.errors?.['required']">
                    Napisz imię lub pseudonim.
                </mat-error>
                <mat-error *ngIf="name.value.length < userNameMinLength && !name.errors?.['required']">
                    Minimalna długość wynosi {{ userNameMinLength }} znaków.
                </mat-error>
                <mat-error *ngIf="name.value.length > userNameMaxLength">
                    Maksymalna długość wynosi {{ userNameMaxLength }} znaków.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="three-quarters-width pt-2" appearance="outline">
                <mat-label>Nazwa firmy</mat-label>
                <input matInput formControlName="company_name" name="company_name" type="text"
                       placeholder="Nazwa firmy"
                       #companyName>
                <mat-hint align="end">
                    {{ companyName.value.length }} / {{ companyNameMaxLength }}
                </mat-hint>
                <mat-error *ngIf="company_name.errors?.['required']">
                    Napisz nazwę firmy lub organizacji.
                </mat-error>
                <mat-error
                    *ngIf="company_name.value.length < companyNameMinLength && !company_name.errors?.['required']">
                    Minimalna długość wynosi {{ companyNameMinLength }} znaków.
                </mat-error>
                <mat-error *ngIf="company_name.value.length > companyNameMaxLength">
                    Maksymalna długość wynosi {{ companyNameMaxLength }} znaków.
                </mat-error>
            </mat-form-field>
        </form>
    </ng-template>
</div>
