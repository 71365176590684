<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row">
        <div *ngIf="isLoading; else content">
            <div class="d-flex justify-content-center mt-5">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <ng-template #content>
            <div class="col-md-6">
                <mat-progress-bar *ngIf="progressBar" class="mt-2" mode="indeterminate"></mat-progress-bar>
                <mat-card class="bg-white mat-elevation-z3">
                    <div class="row pb-2">
                        <div class="col-10">
                            <mat-card-header>
                                <mat-card-title><i class="fa-solid fa-list-check text-primary-color"></i>
                                    Zgłoszenie serwisowe podsumowanie
                                </mat-card-title>
                                <mat-card-subtitle>Informacje na temat zgłoszenia serwisowego.</mat-card-subtitle>
                            </mat-card-header>
                        </div>
                        <div class="col-2">
                            <a class="d-flex flex-row-reverse link-secondary mx-2 mt-2" routerLink="{{ closeLink }}">
                                <mat-icon aria-hidden="false" matTooltip="Zamknij" matTooltipPosition="below">close
                                </mat-icon>
                            </a>
                        </div>
                    </div>
                    <mat-card-content>
                        <ng-container *ngIf="serviceOrder.emergency">
                            <h4 class="text-danger">Pogotowie <b>
                                <i class="fa-solid fa-truck-medical"></i></b></h4>
                        </ng-container>
                        <ng-container *ngIf="authService.user.type !== 'user'">
                            <ng-container *ngIf="serviceOrderReporter">
                                <h5>zgłaszający: <b
                                    class="text-primary-color">{{ serviceOrderReporter.company_name }}</b>
                                </h5>
                            </ng-container>
                        </ng-container>
                        <h5>numer: <b class="text-primary-color">{{ serviceOrder.id }} </b></h5>
                        <h5>typ: <b class="text-primary-color">{{ serviceOrder.type }}</b></h5>
                        <h5>data: <b class="text-primary-color">{{ serviceOrder.date | date:'dd/MM/y' }}</b>
                            godzina: <b class="text-primary-color"> {{ serviceOrder.date | date:'hh:mm' }}</b>
                        </h5>
                        <div class="pt-2">
                            <h4><i class="fa-regular fa-file-lines text-primary-color"></i> Opis:</h4>
                            <ul class="no-bullets">
                                <li>{{ serviceOrder.description }}</li>
                            </ul>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="bg-white mt-3 mat-elevation-z3">
                    <mat-card-header>
                        <mat-card-title><i class="fa-solid fa-wave-square text-primary-color"></i>
                            Status:
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <ul class="no-bullets">
                            <li *ngIf="authService.user.type === 'user' || serviceOrder.status === 'completed'">
                                <h4>
                                    <app-service-order-status-color
                                        [status]="serviceOrder.status"></app-service-order-status-color>
                                </h4>
                            </li>
                        </ul>
                        <app-service-order-status-edit
                            *ngIf="authService.user.type !== 'user' && serviceOrder.status !== 'completed'"
                            [serviceOrder]="serviceOrder" [userType]="authService.user.type"
                            [statusReady]="serviceOrder.status"
                            (status)="updateOrder($event)">
                        </app-service-order-status-edit>
                    </mat-card-content>
                </mat-card>
                <mat-card class="bg-white mt-3 mat-elevation-z3">
                    <mat-card-header>
                        <mat-card-title><i class="fa-solid fa-location-dot text-primary-color"></i>
                            Adres:
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <ul class="no-bullets mt-2">
                            <li>{{ serviceOrder.addresses[0].name }}</li>
                            <li>{{ serviceOrder.addresses[0].street_name }} {{ serviceOrder.addresses[0].street_number }}
                                {{ serviceOrder.addresses[0].apartment_number ? '/ ' + serviceOrder.addresses[0].apartment_number : '' }}
                            </li>
                            <li>{{ serviceOrder.addresses[0].zip_code }} {{ serviceOrder.addresses[0].city_name }}</li>
                            <li><a class="text-decoration-none text-primary-color"
                                   href="tel:{{ serviceOrder.addresses[0].phone_number}}"><i
                                class="fas fa-phone"></i>
                                <a class="text-decoration-none fw-bold text-primary-color"
                                   href="tel:{{ serviceOrder.addresses[0].phone_number}}"> {{ serviceOrder.addresses[0].phone_number }}</a></a>
                            </li>
                            <li><i
                                class="fa-brands fa-intercom text-primary-color"></i> {{ serviceOrder.addresses[0].intercom_code }}
                            </li>
                        </ul>
                        <div class="d-flex justify-content-end">
                            <button *ngIf="authService.user.type === 'admin' && serviceOrder.status !== 'completed'"
                                    class="mx-4" mat-stroked-button
                                    (click)="editAddress(serviceOrder.addresses[0].id)">
                                <i class="fa-solid fa-pen-to-square"></i> Edycja
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="bg-white mt-3 mat-elevation-z3">
                    <mat-card-header>
                        <mat-card-title><i class="fa-regular fa-image text-primary-color"></i>
                            Zdjęcia:
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <ul class="no-bullets mt-2">
                            <ng-container *ngIf="serviceOrder.images.length > 0 else notAdded">
                                <li>ilość: <b class="text-primary-color">{{ serviceOrder.images.length }}</b>
                                </li>
                            </ng-container>
                            <ng-template #notAdded>
                                <li>Nie dodane</li>
                            </ng-template>
                        </ul>
                        <div class="d-flex justify-content-end">
                            <ng-container *ngIf="serviceOrder.images.length > 0 else addButton">
                                <button class="mx-4" mat-stroked-button (click)="showImages()">
                                    <i class="fa-solid fa-eye"></i> Pokaż
                                </button>
                            </ng-container>
                            <ng-template #addButton>
                                <button *ngIf="authService.user.type !== 'user' && serviceOrder.status !== 'completed'"
                                        class="mx-4" mat-stroked-button (click)="addImages()">
                                    <i class="fa-solid fa-plus"></i> Dodaj
                                </button>
                            </ng-template>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card *ngIf="serviceOrder?.type === 'water'" class="bg-white mt-3 mat-elevation-z3">
                    <mat-card-header>
                        <mat-card-title><i class="fa-solid fa-droplet text-primary-color"></i>
                            Protokół wodny:
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <ul class="no-bullets mt-2">
                            <ng-container *ngIf="serviceOrder.water_protocol.length else notAdded">
                                <li>data: <b
                                    class="text-primary-color">{{ serviceOrder.water_protocol[0].date | date:'dd/MM/y' }}</b>
                                    godzina: <b
                                        class="text-primary-color"> {{ serviceOrder.water_protocol[0].date| date:'hh:mm' }}</b>
                                </li>
                            </ng-container>
                            <ng-template #notAdded>
                                <li>Nie dodane</li>
                            </ng-template>
                        </ul>
                        <div class="d-flex justify-content-end">
                            <ng-container *ngIf="serviceOrder.water_protocol.length else addButton">
                                <button class="mx-4" mat-stroked-button (click)="showWaterProtocol()">
                                    <i class="fa-solid fa-eye"></i> Pokaż
                                </button>
                            </ng-container>
                            <ng-template #addButton>
                                <button *ngIf="authService.user.type === 'admin' || authService.user.type === 'member'"
                                        class="mx-4" mat-stroked-button (click)="addWaterProtocol()">
                                    <i class="fa-solid fa-plus"></i> Dodaj
                                </button>
                            </ng-template>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6 mt-3 mt-xl-0">
                <div class="pb-3" *ngIf="authService.user.type !== 'member'">
                    <mat-card class="bg-white mat-elevation-z3">
                        <div *ngIf="authService.user.type === 'admin' && serviceOrder.status !== 'completed'"
                             class="text-end mx-4 mt-3">
                            <button mat-icon-button color="warn" (click)="switchReporterDeleteMode()"
                                    [disabled]="serviceOrder.messages.length <= 0">
                                <ng-container *ngIf="!reporterDeleteMode else reporterDelete">
                                    <mat-icon matTooltip="Delete mode" matTooltipPosition="right">
                                        delete
                                    </mat-icon>
                                </ng-container>
                                <ng-template #reporterDelete>
                                    <mat-icon matTooltip="close mode" matTooltipPosition="right">
                                        close
                                    </mat-icon>
                                </ng-template>
                            </button>
                        </div>
                        <div class="pb-4"
                             [class.chat-message-header]="authService.user.type === 'admin' && serviceOrder.status !== 'completed'">
                            <mat-card-header>
                                <mat-card-title><i class="fa-solid fa-comment text-primary-color"></i>
                                    {{ authService.user.type === 'admin' ? 'Zgłaszający' : 'Wiadomości' }}
                                </mat-card-title>
                            </mat-card-header>
                        </div>
                        <mat-card-content>
                            <div *ngFor="let message of serviceOrder.messages">
                                <app-service-order-message *ngIf="message.chat_type === 'reporter'"
                                                           [deleteMode]="this.reporterDeleteMode"
                                                           [user]="authService.user" (read)="messageRead($event)"
                                                           (reload)="getMessages($event)"
                                                           [message]="message">
                                </app-service-order-message>
                                <div *ngIf="message.chat_type !== 'reporter' && serviceOrder.messages.length < 0"
                                     class="text-center">
                                    <p>Brak wiadomości</p>
                                </div>
                            </div>
                            <div *ngIf="serviceOrder.messages.length === 0" class="text-center">
                                <p>Brak wiadomości</p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div *ngIf="authService.user.type !== 'user'">
                    <mat-card class="bg-white mat-elevation-z3">
                        <div *ngIf="authService.user.type === 'admin' && serviceOrder.status !== 'completed'"
                             class="text-end mx-4 mt-3">
                            <button mat-icon-button color="warn" (click)="switchEmployeeDeleteMode()"
                                    [disabled]="serviceOrder.messages.length <= 0">
                                <ng-container *ngIf="!employeeDeleteMode else employeeDelete">
                                    <mat-icon matTooltip="Delete mode" matTooltipPosition="right">
                                        delete
                                    </mat-icon>
                                </ng-container>
                                <ng-template #employeeDelete>
                                    <mat-icon matTooltip="close mode" matTooltipPosition="right">
                                        close
                                    </mat-icon>
                                </ng-template>
                            </button>
                        </div>
                        <div class="pb-4"
                             [class.chat-message-header]="authService.user.type === 'admin' && serviceOrder.status !== 'completed'">
                            <mat-card-header>
                                <mat-card-title><i class="fa-solid fa-comment text-primary-color"></i>
                                    {{ authService.user.type === 'admin' ? 'Pracownicy' : 'Wiadomości' }}
                                </mat-card-title>
                            </mat-card-header>
                        </div>
                        <mat-card-content>
                            <div *ngFor="let message of serviceOrder.messages">
                                <app-service-order-message *ngIf="message.chat_type === 'employee'"
                                                           [deleteMode]="employeeDeleteMode"
                                                           [user]="authService.user" (read)="messageRead($event)"
                                                           (reload)="getMessages($event)"
                                                           [message]="message">
                                </app-service-order-message>
                                <div *ngIf="message.chat_type !== 'employee' && serviceOrder.messages.length < 0"
                                     class="text-center">
                                    <p>Brak wiadomości.</p>
                                </div>
                            </div>
                            <div *ngIf="serviceOrder.messages.length === 0" class="text-center">
                                <p>Brak wiadomości.</p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <app-service-order-comment *ngIf="authService.user.type === 'admin' && serviceOrder.comment !== null"
                                           [serviceOrderID]="serviceOrder.id"
                                           (refreshServiceOrder)="refreshServiceOrder()"
                                           [comment]="serviceOrder.comment"></app-service-order-comment>
                <div class="d-flex justify-content-center justify-content-md-end py-5">
                    <button mat-stroked-button routerLink="{{ closeLink }}"><i
                        class="fa-solid fa-rotate-left"></i> Zamknij
                    </button>
                    <button *ngIf="authService.user.type === 'admin' && serviceOrder.comment === null" class="ms-2"
                            mat-stroked-button
                            (click)="addComment()">
                        <i class="fa-solid fa-file-signature"></i> Komentarz
                    </button>
                    <button *ngIf="serviceOrder.status !== 'completed'" class="mx-2" mat-stroked-button
                            (click)="addMessage()"><i
                        class="fa-solid fa-comment"></i> Wiadomość
                    </button>
                </div>
            </div>
        </ng-template>
    </div>
</div>
