<ng-container *ngIf="!editMode else editStatus">
    <ul class="no-bullets">
        <li>
            <h4>
                <app-service-order-status-color
                    [status]="serviceOrder.status"></app-service-order-status-color>
            </h4>
        </li>
    </ul>
</ng-container>
<ng-template #editStatus>
    <div class="mx-4">
        <form [formGroup]="statusForm">
            <mat-form-field class="mx-xl-2" appearance="outline">
                <mat-label>status:</mat-label>
                <mat-select formControlName="status">
                    <mat-option [value]="'created'" disabled>utworzone</mat-option>
                    <mat-option [value]="'in_progress'">w_trakcie</mat-option>
                    <mat-option [value]="'not_finished'">nie_skończone</mat-option>
                    <mat-option [value]="'ready'">gotowe</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</ng-template>
<div class="d-flex justify-content-end">
    <ng-container *ngIf="userType === 'admin'">
        <button *ngIf="editMode && statusReady === 'ready'" mat-stroked-button color="warn" class="mx-1"
                (click)="setStatusComplete()"><i class="fa-solid fa-check"></i> Zakończ
        </button>
    </ng-container>
    <app-service-order-status-edit-button (edit)="changeEditMode()"
                                          (update)="updateOrderStatus()">
    </app-service-order-status-edit-button>
</div>
