<div class="pt-2">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center py-2">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <form [formGroup]="workingTimeForm">
            <mat-form-field appearance="outline" color="primary">
                <mat-label>Data</mat-label>
                <input matInput [matDatepicker]="startPicker" [formControl]="date">
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker (closed)="setStartDate()"></mat-datepicker>
            </mat-form-field>
            <button class="mx-2 mx-lg-3" mat-stroked-button color="primary" (click)="setCurrentDateTime()">
                <mat-icon fontIcon="av_timer"></mat-icon>
                Teraz
            </button>
            <div class="pt-4">
                <h5><i class="fa-regular fa-clock text-primary-color"></i> Godzina:</h5>
                <mat-form-field class="pt-2" appearance="outline" color="primary">
                    <mat-label>godzina:</mat-label>
                    <mat-select (selectionChange)="setHour($event)" formControlName="hour">
                        <ng-container *ngFor="let hour of hours">
                            <mat-option [value]="hour">{{ hour }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="mx-lg-2" appearance="outline" color="primary">
                    <mat-label>minut:</mat-label>
                    <mat-select (selectionChange)="setMinutes($event)" formControlName="minute">
                        <mat-option [value]="0">00</mat-option>
                        <mat-option [value]="30">30</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-form-field *ngIf="endMode" class="full-width mt-2" appearance="outline">
                <mat-label>Komentarz:</mat-label>
                <textarea matInput formControlName="comment" name="comment" type="text"
                          placeholder="Day comment" #_comment></textarea>
                <mat-hint align="end">
                    {{ _comment.value.length }} / {{ commentMaxLength }}
                </mat-hint>
                <mat-error *ngIf="comment.value.length > commentMaxLength">
                    Maksymalna długość wynosi {{ commentMaxLength }} znaków.
                </mat-error>
            </mat-form-field>
        </form>
    </ng-template>
</div>
