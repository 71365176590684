<mat-toolbar>
    <span>Dodaj wiadomość <i class="fa-solid fa-comment"></i></span>
    <span class="toolbar-spacer"></span>
    <button class="text-white" mat-icon-button matTooltip="Zamknij" matTooltipPosition="below" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <mat-checkbox *ngIf="authService.user.type === 'admin'" [(ngModel)]="reporterMessage" color="primary">
        Wiadomość dla zgłaszającego
    </mat-checkbox>
    <form [formGroup]="chatMessageForm">
        <mat-form-field class="full-width mt-2" appearance="outline">
            <mat-label>Wiadomość:</mat-label>
            <textarea matInput formControlName="message" name="message" type="text"
                      placeholder="Message" #chatMessage></textarea>
            <mat-hint align="end">
                {{ chatMessage.value.length }} / {{ chatMessageMaxLength }}
            </mat-hint>
            <mat-error *ngIf="message.errors?.['required']">
                Napisz wiadomość.
            </mat-error>
            <mat-error *ngIf="message.value.length < chatMessageMinLength && !message.errors?.['required']">
                Minimalna długość wynosi {{ chatMessageMinLength }} znaków.
            </mat-error>
            <mat-error *ngIf="message.value.length > chatMessageMaxLength">
                Maksymalna długość wynosi {{ chatMessageMaxLength }} znaków.
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<div class="d-flex justify-content-end pb-4">
    <button class="full-width mx-2" mat-raised-button [mat-dialog-close]="true"
            matTooltip="Zamknij okienko" matTooltipPosition="below">
        <i class="fa-solid fa-xmark"></i> Zamknij
    </button>
    <button class="full-width mx-2" mat-raised-button color="warn" (click)="sendChatMessage()"
            matTooltip="Delete" matTooltipPosition="below"
            [disabled]="chatMessageForm.invalid">Wyślij <i class="fa-solid fa-paper-plane"></i></button>
</div>
