<div class="py-2 px-2 py-md-5 px-md-5">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center mt-5">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <div class="row">
            <div class="col-md-6">
                <mat-card class="bg-white mat-elevation-z3">
                    <div class="d-flex flex-row-reverse link-secondary mx-2 mt-2" (click)="back()"
                         style="cursor: pointer" (keydown.backspace)="back()" role="button" aria-hidden="true">
                        <mat-icon aria-hidden="false" matTooltip="Zamknij" matTooltipPosition="below">close</mat-icon>
                    </div>
                    <div class="col-9 header">
                        <mat-card-header>
                            <mat-card-title><i class="fa-solid fa-droplet text-primary-color"></i>
                                Protokół wodny
                            </mat-card-title>
                            <mat-card-subtitle>Informacje na temat plombowania liczników.</mat-card-subtitle>
                        </mat-card-header>
                    </div>
                    <mat-card-content>
                        <div class="pt-4">
                            <h4>data: <b class="text-primary-color">{{ waterProtocol.date | date:'dd/MM/y' }}</b>
                                godzina: <b class="text-primary-color"> {{ waterProtocol.date | date:'hh:mm' }}</b>
                            </h4>
                        </div>
                        <div class="pt-2">
                            <ng-container *ngIf="!editCommentMode else commentForm">
                                <h4><i class="fa-regular fa-file-lines text-primary-color"></i> Komentarz:</h4>
                                <ul class="no-bullets">
                                    <li>{{ waterProtocol.comments }}</li>
                                </ul>
                                <div *ngIf="authService.user.type !== 'user' && !isArchive"
                                     class="d-flex justify-content-end">
                                    <button class="mx-4" mat-stroked-button (click)="switchEditCommentMode()">
                                        <i class="fa-solid fa-pen-to-square"></i> Edytuj
                                    </button>
                                </div>
                            </ng-container>
                            <ng-template #commentForm>
                                <app-water-protocol-form [comment]="waterProtocol.comments" [editMode]="true"
                                                         #data></app-water-protocol-form>
                                <app-save-button [invalid]="data.waterProtocolForm.invalid"
                                                 (closeForm)="switchEditCommentMode()"
                                                 (save)="updateComment()"
                                                 [addMode]="false">
                                </app-save-button>
                            </ng-template>
                        </div>
                        <div class="pt-2">
                            <app-water-meter [waterMeters]="waterProtocol.water_meters"
                                             [editMode]="authService.user.type !== 'user' && !isArchive"
                                             (edit)="updateWaterMeter($event)"></app-water-meter>
                        </div>
                    </mat-card-content>
                    <mat-progress-bar mode="indeterminate" *ngIf="isUpdating" color="primary"></mat-progress-bar>
                </mat-card>

            </div>
        </div>
        <div class="col-md-6 text-center py-5">
            <button mat-stroked-button (click)="back()">
                <i class="fa-solid fa-rotate-left"></i> Zamknij
            </button>
            <ng-container *ngIf="authService.user.type === 'admin' && !isArchive">
                <button class="mx-2" mat-stroked-button color="warn" (click)="delete(waterProtocol.id)">
                    <i class="fa-solid fa-trash"></i> Usuń
                </button>
            </ng-container>
        </div>
    </ng-template>
</div>
