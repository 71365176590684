<div class="py-4">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center py-2">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <form [formGroup]="intercomCodeForm">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Ulica:</mat-label>
                <input matInput formControlName="address" name="address" type="text" placeholder="Ulica"
                       #displayAddress>
                <mat-hint align="end">
                    {{ displayAddress.value.length }} / {{ addressMaxLength }}
                </mat-hint>
                <mat-error *ngIf="address.errors?.['required']">
                    Podaj adres.
                </mat-error>
                <mat-error *ngIf="address.value.length < addressMinLength && !address.errors?.['required']">
                    Minimalna długość wynosi {{ addressMinLength }} znaków.
                </mat-error>
                <mat-error *ngIf="address.value.length > addressMaxLength">
                    Maksymalna długość wynosi {{ addressMaxLength }} znaków.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width py-2" appearance="outline">
                <mat-label>Numer bloku:</mat-label>
                <input matInput formControlName="number" name="number" type="text" placeholder="Numer"
                       #displayNumber>
                <mat-hint align="end">
                    {{ displayNumber.value.length }} / {{ numberMaxLength }}
                </mat-hint>
                <mat-error *ngIf="number.errors?.['required']">
                    Podaj numer bloku.
                </mat-error>
                <mat-error *ngIf="number.value.length < numberMinLength && !number.errors?.['required']">
                    Minimalna długość wynosi {{ numberMinLength }} znaków.
                </mat-error>
                <mat-error *ngIf="number.value.length > numberMaxLength">
                    Maksymalna długość wynosi  {{ numberMaxLength }} znaków.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Kod domofonu:</mat-label>
                <input matInput formControlName="code" name="code" type="text" placeholder="Code"
                       #displayCode>
                <mat-hint align="end">
                    {{ displayCode.value.length }} / {{ codeMaxLength }}
                </mat-hint>
                <mat-error *ngIf="code.errors?.['required']">
                    Podaj kod domofonu.
                </mat-error>
                <mat-error *ngIf="code.value.length < codeMinLength && !code.errors?.['required']">
                    Minimalna długość wynosi {{ codeMinLength }} znaków.
                </mat-error>
                <mat-error *ngIf="code.value.length > codeMaxLength">
                    Maksymalna długość wynosi  {{ codeMaxLength }} znaków.
                </mat-error>
            </mat-form-field>
        </form>
    </ng-template>
</div>
