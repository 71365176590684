<mat-card class="bg-white mat-elevation-z3">
    <mat-card-header>
        <mat-card-title>
            <mat-icon class="mx-1" color="primary">keyboard</mat-icon>
            Kody domofonów
        </mat-card-title>
        <mat-card-subtitle>
            Sprawdź lub edytuj kody domofonów.
        </mat-card-subtitle>
        <div class="mx-3" *ngIf="authService.user.type === 'admin'">
            <button mat-fab color="warn" matTooltip="Add working time" (click)="addIntercomCode()"
                    matTooltipPosition="right" aria-label="Add working time">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="pt-4">
            <mat-form-field appearance="outline" style="width: 75%">
                <mat-label>Ulica ...</mat-label>
                <input type="text" matInput placeholder="Street ..."
                       (keyup)="setSearchFilter()" [(ngModel)]="searchFilter">
                <mat-icon matSuffix color="primary">search</mat-icon>
            </mat-form-field>
        </div>
        <div class="table-container pt-2 pb-4">
            <table mat-table [dataSource]="intercomCodes$" matSort (matSortChange)="sortData($event)"
                   matSortActive="address" matSortDisableClear matSortDirection="asc">

                <!-- address column -->
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="address">ulica</th>
                    <td mat-cell *matCellDef="let intercomCode">{{ intercomCode.address }}</td>
                </ng-container>

                <!-- number column -->
                <ng-container matColumnDef="number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="number">numer</th>
                    <td mat-cell *matCellDef="let intercomCode">{{ intercomCode.number }}</td>
                </ng-container>

                <!-- code column -->
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="code">kod</th>
                    <td mat-cell *matCellDef="let intercomCode">{{ intercomCode.code }}</td>
                </ng-container>

                <!-- actions column -->
                <ng-container matColumnDef="actions">
                    <th class="table-cell-width" mat-header-cell *matHeaderCellDef>akcja</th>
                    <td mat-cell *matCellDef="let intercomCode">
                        <div class="d-flex">
                            <button mat-mini-fab color="indigo" matTooltip="Pokaż"
                                    (click)="showIntercomCode(intercomCode.id)"
                                    matTooltipPosition="left" aria-label="Show">
                                <mat-icon>remove_red_eye</mat-icon>
                            </button>
                            <button class="mx-2" mat-mini-fab matTooltip="Edytuj"
                                    *ngIf="authService.user.type === 'admin'"
                                    (click)="editIntercomCode(intercomCode.id)"
                                    matTooltipPosition="above" aria-label="Edit">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-mini-fab color="warn" matTooltip="Usuń"
                                    *ngIf="authService.user.type === 'admin'"
                                    (click)="removeIntercomCode(intercomCode.id)"
                                    matTooltipPosition="right" aria-label="Delete">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator class="bg-white" [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)">
        </mat-paginator>
        <ng-container *ngIf="!intercomCodes$.data.length">
            <div class="text-center py-5">
                <p>Brak kodów.</p>
            </div>
        </ng-container>
        <div class="mt-3" *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading" color="primary"></mat-progress-bar>
        </div>
    </mat-card-content>
</mat-card>
