<mat-card class="bg-white mat-elevation-z3">
    <mat-card-header>
        <mat-card-title>
            <mat-icon class="mx-1" color="primary">price_change</mat-icon>
            {{ isArchive === '1' ? 'Archiwum depozytów' : 'Depozyty' }}
        </mat-card-title>
        <mat-card-subtitle>
            {{ isArchive === '1' ? 'Sprawdź archiwalne depozyty' : 'Dodaj, sprawdź lub edytuj depozyt.' }}
        </mat-card-subtitle>
        <div class="mx-3" *ngIf="authService.user.type === 'admin' && isArchive !== '1'">
            <button mat-fab color="warn" matTooltip="Dodaj depozyt" (click)="addDeposit()"
                    matTooltipPosition="right" aria-label="Dodaj depozyt">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="pt-4">
            <mat-form-field appearance="outline" style="width: 75%">
                <mat-label>Inwestor ...</mat-label>
                <input type="text" matInput placeholder="Inwestor ..."
                       (keyup)="setSearchFilter()" [(ngModel)]="searchFilter">
                <mat-icon matSuffix color="primary">search</mat-icon>
            </mat-form-field>
        </div>
        <div class="table-container pt-2 pb-4">
            <table mat-table [dataSource]="deposits$" matSort (matSortChange)="sortData($event)"
                   matSortActive="return_date" matSortDisableClear matSortDirection="asc">

                <!-- return_date column -->
                <ng-container matColumnDef="return_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="return_date">Data zwrotu</th>
                    <td mat-cell *matCellDef="let deposit">{{ deposit.return_date  | date: 'dd/MM/yyyy' }}</td>
                </ng-container>

                <!-- investor column -->
                <ng-container matColumnDef="investor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="investor">Inwestor</th>
                    <td mat-cell *matCellDef="let deposit">{{ deposit.investor }}</td>
                </ng-container>

                <!-- contract_no column -->
                <ng-container matColumnDef="contract_no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="contract_no">Numer kontraktu</th>
                    <td mat-cell *matCellDef="let deposit">{{ deposit.contract_no }}</td>
                </ng-container>

                <!-- contract_date column -->
                <ng-container matColumnDef="contract_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="contract_date">Data kontraktu</th>
                    <td mat-cell *matCellDef="let deposit">{{ deposit.contract_date  | date: 'dd/MM/yyyy' }}</td>
                </ng-container>

                <!-- amount column -->
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="amount">Wartość</th>
                    <td mat-cell *matCellDef="let deposit"> {{ deposit.amount | currency }}</td>
                </ng-container>

                <!-- actions column -->
                <ng-container matColumnDef="actions">
                    <th class="table-cell-width" mat-header-cell *matHeaderCellDef>akcje</th>
                    <td mat-cell *matCellDef="let deposit">
                        <div class="d-flex">
                            <button mat-mini-fab color="indigo" matTooltip="Pokaż"
                                    (click)="showDeposit(deposit.id)"
                                    matTooltipPosition="right" aria-label="Pokaż">
                                <mat-icon>remove_red_eye</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator class="bg-white" [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)">
        </mat-paginator>
        <ng-container *ngIf="!deposits$.data.length">
            <div class="text-center py-5">
                <p>Brak depozytu.</p>
            </div>
        </ng-container>
        <div class="mt-3" *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading" color="primary"></mat-progress-bar>
        </div>
    </mat-card-content>
</mat-card>
