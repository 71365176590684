<mat-toolbar>
    <span>Dodaj komentarz <i class="fa-solid fa-file-signature"></i></span>
    <span class="toolbar-spacer"></span>
    <button class="text-white" mat-icon-button matTooltip="Zamknij" matTooltipPosition="below" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <app-comment-form #data></app-comment-form>
</mat-dialog-content>
<div class="d-flex justify-content-end pb-4">
    <button class="full-width mx-2" mat-raised-button [mat-dialog-close]="true"
            matTooltip="Cancel - close modal" matTooltipPosition="below">
        <i class="fa-solid fa-xmark"></i> Zamknij
    </button>
    <button class="full-width mx-2" mat-raised-button color="warn" (click)="addComment()"
            matTooltip="Send" matTooltipPosition="below"
            [disabled]="data.commentForm.invalid">Dodaj <i class="fa-solid fa-plus"></i></button>
</div>
