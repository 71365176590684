<div class="d-flex justify-content-end pt-2 pb-3">
    <button class="full-width mx-2" mat-raised-button (click)="closeEmit()"
            matTooltip="Zamknij" matTooltipPosition="below">
        <i class="fa-solid fa-xmark"></i> Zamknij
    </button>
    <ng-container *ngIf="showMode else editMode">
        <button class="full-width mx-2" mat-raised-button color="indigo" (click)="saveEmit()"
                matTooltip="Edit" matTooltipPosition="below">
            Edytuj <i class="fa-solid fa-pen"></i>
        </button>
    </ng-container>
    <ng-template #editMode>
        <button class="full-width mx-2" mat-raised-button color="warn" (click)="saveEmit()"
                matTooltip="Save/Add" matTooltipPosition="below" [disabled]="invalid">
            Zapisz <i class="fa-solid fa-floppy-disk"></i>
        </button>
    </ng-template>
</div>
