<mat-toolbar class="bg-danger">
    <span>Pytanie <i class="fa-solid fa-question"></i></span>
    <span class="toolbar-spacer"></span>
    <button class="text-white" mat-icon-button matTooltip="Zamknij" matTooltipPosition="below" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <div class="container text-center">
        <h4 class="pt-3">Czy jesteś pewny?</h4>
        <h4>Zakończyć zlecenie o numerze: {{ serviceOrderID }}</h4>
        <div class="py-2">
            <mat-checkbox color="primary" [(ngModel)]="checked">Tak, zakończyć.</mat-checkbox>
        </div>
    </div>
</mat-dialog-content>
<div class="d-flex justify-content-end pb-4">
    <button class="full-width mx-2" mat-raised-button [mat-dialog-close]="true"
            matTooltip="Cancel - close modal" matTooltipPosition="below">
        <i class="fa-solid fa-xmark"></i> NIE
    </button>
    <button class="full-width mx-2" mat-raised-button color="warn" (click)="completed()"
            matTooltip="Completed" matTooltipPosition="below"
            [disabled]="!checked">TAK <i class="fa-solid fa-check"></i></button>
</div>
