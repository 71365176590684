<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row">
        <div class="col-md-6">
            <mat-card class="bg-white mat-elevation-z3">
                <div class="row">
                    <div class="col-md-6">
                        <mat-card-header>
                            <mat-card-title>
                                <mat-icon class="mx-1" color="primary">more_time</mat-icon>
                                Dodanie czasu pracy
                            </mat-card-title>
                            <mat-card-subtitle>Dodaj dzień i godzinę rozpoczęcia pracy.</mat-card-subtitle>
                        </mat-card-header>
                    </div>
                </div>
                <mat-card-content>
                    <div class="pt-4">
                        <app-working-time-form #data></app-working-time-form>
                        <app-working-time-save-button
                            [invalid]="data.workingTimeForm.invalid"
                            (closeForm)="closeForm()"
                            (save)="addWorkingTime()">
                        </app-working-time-save-button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
