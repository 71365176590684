<div class="py-2 px-2 py-md-5 px-md-5">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center py-2">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <div class="row">
            <div class="col-md-6">
                <mat-card class="bg-white mat-elevation-z3">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-card-header>
                                <mat-card-title>
                                    <mat-icon class="mx-1" color="primary">more_time</mat-icon>
                                    Czas pracy
                                </mat-card-title>
                                <mat-card-subtitle>Dzień oraz czas pracy wraz z komentarzem.</mat-card-subtitle>
                            </mat-card-header>
                        </div>
                    </div>
                    <mat-card-content>
                        <div *ngIf="workingTime" class="py-4">
                            <h4><i class="fa-solid fa-calendar-days text-primary-color"></i> Data rozpoczęcia: <b
                                class="text-primary-color">{{ workingTime.start | date:'dd/MM/y' }}</b></h4>
                            <h4 class="pt-1"><i class="fa-regular fa-clock text-primary-color"></i> Godzina: <b
                                class="text-primary-color">{{ workingTime.start | date:'HH:mm' }}</b></h4>
                            <div class="pt-3">
                                <h4><i class="fa-solid fa-calendar-days text-primary-color"></i> Data zakończenia: <b
                                    class="text-primary-color">{{ workingTime.end| date:'dd/MM/y' }}</b></h4>
                                <h4 class="pt-1"><i class="fa-regular fa-clock text-primary-color"></i> Godzina: <b
                                    class="text-primary-color">{{ workingTime.end | date:'HH:mm' }}</b></h4>
                            </div>
                            <div class="pt-2">
                                <h4 class="pt-1"><i class="fa-solid fa-clock text-primary-color"></i> Ilość godzin: <b
                                    class="text-primary-color">
                                    {{ workingTime.total_hours }}h{{ workingTime.total_minutes }}m</b></h4>
                            </div>
                            <div *ngIf="workingTime.comment" class="pt-3">
                                <h4><i class="fa-regular fa-comment text-primary-color"></i> Komentarz: </h4>
                                <p class="mx-4">{{ workingTime.comment }}</p>
                            </div>
                        </div>
                        <app-working-time-save-button
                            [invalid]="false" [showMode]="true"
                            (closeForm)="closeForm()"
                            (save)="editWorkingTime()">
                        </app-working-time-save-button>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </ng-template>
</div>
