<mat-card class="bg-white mat-elevation-z3">
    <mat-card-header>
        <mat-card-title>
            <mat-icon class="mx-1" color="primary">supervisor_account</mat-icon>
            Lista Użytkowników
        </mat-card-title>
        <mat-card-subtitle>
            Informacje na temat użytkowników i organizacji
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="pt-4 pb-3">
            <mat-accordion>
                <mat-expansion-panel class="bg-white" (opened)="cleanFilter()" (closed)="cleanFilter()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa-solid fa-filter text-primary-color mx-2"></i> Filtry
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field class="py-2 mx-1 mx-xl-3" appearance="outline" color="primary">
                        <mat-label>Typ</mat-label>
                        <mat-select (selectionChange)="setTypeFilter($event)"
                                    [(ngModel)]="typeFilter">
                            <mat-option [value]="''">wszyscy</mat-option>
                            <mat-option [value]="'admin'">admin</mat-option>
                            <mat-option [value]="'member'">pracownik</mat-option>
                            <mat-option [value]="'user'">użytkownik</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-stroked-button color="primary" (click)="cleanFilter()">
                        <mat-icon fontIcon="cleaning_services"></mat-icon>
                        wyczyść
                    </button>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="users$" matSort (matSortChange)="sortData($event)" matSortActive="id"
                   matSortDisableClear matSortDirection="desc">

                <!-- id column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="id">id</th>
                    <td mat-cell *matCellDef="let user">{{ user.id }}</td>
                </ng-container>

                <!-- type column -->
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="type">typ</th>
                    <td mat-cell *matCellDef="let user">{{ user.type }}</td>
                </ng-container>

                <!-- name column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="name">imię</th>
                    <td mat-cell *matCellDef="let user">{{ user.name }}</td>
                </ng-container>

                <!-- company name column -->
                <ng-container matColumnDef="company_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="company_name">organizacja</th>
                    <td mat-cell *matCellDef="let user">{{ user.company_name }}</td>
                </ng-container>

                <!-- action column -->
                <ng-container matColumnDef="action">
                    <th class="table-cell-width" mat-header-cell *matHeaderCellDef>akcja</th>
                    <td mat-cell *matCellDef="let user">
                        <div class="d-flex">
                            <button *ngIf="user.type === 'user'" mat-mini-fab color="warn" matTooltip="Dodaj zgłoszenie"
                                    (click)="addServiceOrder(user.id)"
                                    matTooltipPosition="left" aria-label="Show">
                                <mat-icon>post_add</mat-icon>
                            </button>
                            <button  class="mx-2" mat-mini-fab color="indigo" matTooltip="Edytuj użytkownika"
                                    (click)="editUser(user.id)"
                                    matTooltipPosition="right" aria-label="Show">
                                <mat-icon>remove_red_eye</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator class="bg-white" [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)">
        </mat-paginator>
        <ng-container *ngIf="!users$.data.length">
            <div class="text-center py-5">
                <p>Brak użytkowników</p>
            </div>
        </ng-container>
        <div class="mt-3" *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading" color="primary"></mat-progress-bar>
        </div>
    </mat-card-content>
</mat-card>
