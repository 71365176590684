<mat-toolbar color="primary">
    <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Show/Hide sidenav" matTooltipPosition="below"
            style="color: #ffffff">
        <mat-icon>menu</mat-icon>
    </button>
    <span class="mx-1">{{ getTitle() }}</span>
    <span class="toolbar-spacer"></span>
    <div class="account-button">
        <button mat-button [matMenuTriggerFor]="menu" matTooltip="Profil" matTooltipPosition="left">
            <mat-icon>account_circle</mat-icon>
            {{ user?.name }}
        </button>
    </div>
    <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="profile">
            <mat-icon>person</mat-icon>
            Profil
        </button>
        <button mat-menu-item (click)="logoutEmit()">
            <mat-icon>logout</mat-icon>
            Wyloguj
        </button>
    </mat-menu>
</mat-toolbar>
