<ng-container *ngIf="!editMode; else change">
    <button class="mx-4" mat-stroked-button (click)="changeEditMode()">
        <i class="fa-solid fa-bolt"></i> Zmień
    </button>
</ng-container>
<ng-template #change>
    <div class="mx-4">
        <button mat-stroked-button color="warn" class="mx-2" (click)="updateOrder()" [disabled]="formInvalid">
            <i class="fa-solid fa-floppy-disk"></i> Zapisz
        </button>
        <button mat-stroked-button color="primary" (click)="changeEditMode()">
            Anuluj <i class="fa-solid fa-xmark"></i>
        </button>
    </div>
</ng-template>
