<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row">
        <div class="col-md-6">
            <mat-card class="bg-white mat-elevation-z3">
                <div class="row">
                    <div class="col-md-6">
                        <mat-card-header>
                            <mat-card-title>
                                <i class="fa-solid fa-stopwatch text-primary-color"></i>
                                Licznik wody aktualizacja
                            </mat-card-title>
                            <mat-card-subtitle>Aktualizacja lub zmiana danych licznika wody.</mat-card-subtitle>
                        </mat-card-header>
                    </div>
                </div>
                <mat-card-content>
                    <app-water-meter-form [waterMeter]="waterMeter" [updateMode]="true" (closeForm)="closeForm()"
                                          (update)="updateWaterMeter()" #meter></app-water-meter-form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>



