<div class="py-2 px-2 py-md-5 px-md-5">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center mt-5">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <div class="row">
            <div class="col-md-6">
                <mat-card class="bg-white mat-elevation-z3">
                    <div class="d-flex flex-row-reverse link-secondary mx-2 mt-2" (click)="back()"
                         style="cursor: pointer" (keydown.backspace)="back()" role="button" aria-hidden="true">
                        <mat-icon aria-hidden="false" matTooltip="Zamknij" matTooltipPosition="below">close</mat-icon>
                    </div>
                    <div class="col-9 header">
                        <mat-card-header>
                            <mat-card-title>
                                <mat-icon class="mx-1" color="primary">price_change</mat-icon>
                                Depozyt
                            </mat-card-title>
                            <mat-card-subtitle>Szczegółowe informacje na temat depozytu.</mat-card-subtitle>
                        </mat-card-header>
                    </div>
                    <mat-card-content>
                        <div class="pt-4">
                            <h4><i class="fa-solid fa-building-user text-primary-color"></i> Investor:</h4>
                            <ul class="no-bullets">
                                <li><h5>Imię: <b class="text-primary-color">{{ deposit.investor }}</b></h5>
                                </li>
                            </ul>
                            <h4><i class="fa-solid fa-file-signature text-primary-color"></i> Contract:</h4>
                            <ul class="no-bullets">
                                <li><h5>Numer: <b class="text-primary-color">{{ deposit.contract_no }}</b></h5></li>
                                <li><h5>Data kontraktu: <b
                                    class="text-primary-color">{{ deposit.contract_date | date: 'dd/MM/yyyy' }}</b></h5>
                                </li>
                                <li><h5>Wartość: <b class="text-primary-color">{{ deposit.amount | currency }}</b></h5>
                                </li>
                                <li><h5>Data zwrotu: <b
                                    class="text-danger">{{ deposit.return_date | date: 'dd/MM/yyyy' }}</b></h5>
                                </li>
                            </ul>
                            <div *ngIf="deposit.comment" class="pt-2">
                                <h4><i class="fa-solid fa-comment text-primary-color"></i> Komentarz: </h4>
                                <p class="mx-4">{{ deposit.comment }}</p>
                            </div>
                            <div *ngIf="deposit.status" class="pt-2 text-center">
                                <h4 class="text-success"><i class="fa-solid fa-thumbs-up text-success"></i> Zakończony </h4>
                            </div>
                        </div>
                        <div *ngIf="!deposit.status" class="py-3">
                            <button class="full-width mx-2" mat-stroked-button color="warn" (click)="completed()"
                                    matTooltip="Save/Add" matTooltipPosition="below">
                                Zakończ <i class="fa-solid fa-thumbs-up"></i>
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="col-md-6 text-center py-5">
            <button mat-stroked-button (click)="back()">
                <i class="fa-solid fa-rotate-left"></i> Zamknij
            </button>
            <button *ngIf="!deposit.status" class="mx-2" mat-stroked-button (click)="editDeposit()"><i
                class="fa-solid fa-pen-to-square"></i>
                Edytuj
            </button>
        </div>
    </ng-template>
</div>
