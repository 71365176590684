<div class="pt-2">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center py-2">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <form [formGroup]="addressForm">
            <mat-form-field class="half-width mt-2" appearance="outline">
                <mat-label>Numer telefonu:</mat-label>
                <input matInput formControlName="phone_number" name="phone_number" type="text"
                       placeholder="Numer telefonu"
                       #phoneNumber>
                <mat-hint align="end">
                    {{ phoneNumber.value.length }} / {{ phoneNumberMaxLength }}
                </mat-hint>
                <mat-error *ngIf="phone_number.errors?.['required']">
                    Podaj numer telefonu.
                </mat-error>
                <mat-error
                    *ngIf="phone_number.value.length < phoneNumberMinLength && !phone_number.errors?.['required']">
                    Minimalna długość wynosi {{ phoneNumberMinLength }} znaków.
                </mat-error>
                <mat-error *ngIf="phone_number.value.length > phoneNumberMaxLength">
                    Maksymalna długość wynosi {{ phoneNumberMaxLength }} znaków.
                </mat-error>
            </mat-form-field>
            <div class="mt-2">
                <h4>Adres:</h4>
                <mat-form-field class="three-quarters-width" appearance="outline">
                    <mat-label>Imię:</mat-label>
                    <input matInput formControlName="name" name="name" type="text" placeholder="Imię"
                           #residentName>
                    <mat-hint align="end">
                        {{ residentName.value.length }} / {{ nameMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="name.errors?.['required']">
                        Podaj imię osoby, u której jest awaria.
                    </mat-error>
                    <mat-error *ngIf="name.value.length < nameMinLength && !name.errors?.['required']">
                        Minimalna długość wynosi {{ nameMinLength }} znaków.
                    </mat-error>
                    <mat-error *ngIf="name.value.length > nameMaxLength">
                        Maksymalna długość wynosi{{ nameMaxLength }} znaków.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="three-quarters-width mt-2" appearance="outline">
                    <mat-label>Ulica:</mat-label>
                    <input matInput formControlName="street_name" name="street_name" type="text"
                           placeholder="Ulica" #streetName>
                    <mat-hint align="end">
                        {{ streetName.value.length }} / {{ streetNameMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="street_name.errors?.['required']">
                        Podaj nazwę ulicy.
                    </mat-error>
                    <mat-error
                        *ngIf="street_name.value.length < streetNameMinLength && !street_name.errors?.['required']">
                        Minimalna długość wynosi {{ streetNameMinLength }} znaków.
                    </mat-error>
                    <mat-error *ngIf="street_name.value.length > streetNameMaxLength">
                        Maksymalna długość wynosi {{ streetNameMaxLength }} znaków.
                    </mat-error>
                </mat-form-field>
                <mat-form-field style="width: 30%" appearance="outline">
                    <mat-label>Numer:</mat-label>
                    <input matInput formControlName="street_number" name="street_number" type="text"
                           placeholder="Numer" #streetNumber>
                    <mat-hint align="end">
                        {{ streetNumber.value.length }} / {{ streetNumberMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="street_number.errors?.['required']">
                        Podaj numer ulicy.
                    </mat-error>
                    <mat-error
                        *ngIf="street_number.value.length < streetNumberMinLength && !street_number.errors?.['required']">
                        Minimalna długość wynosi {{ streetNumberMinLength }} znaków.
                    </mat-error>
                    <mat-error *ngIf="street_number.value.length > streetNumberMaxLength">
                        Maksymalna długość wynosi{{ streetNumberMaxLength }} znaków.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mx-2" style="width: 30%" appearance="outline">
                    <mat-label>Numer mieszkania:</mat-label>
                    <input matInput formControlName="apartment_number" name="apartment_number" type="text"
                           placeholder="Numer mieszkania" #apartmentNumber>
                    <mat-hint align="end">
                        {{ apartmentNumber.value.length }} / {{ apartmentNumberMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="apartment_number.value.length > apartmentNumberMaxLength">
                        Maksymalna długość wynosi {{ apartmentNumberMaxLength }} znaków.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mt-2" style="width: 50%" appearance="outline">
                    <mat-label>Miasto:</mat-label>
                    <input matInput formControlName="city_name" name="city_name" type="text"
                           placeholder="Miasto" #cityName>
                    <mat-hint align="end">
                        {{ cityName.value.length }} / {{ cityNameMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="city_name.errors?.['required']">
                        Podaj nazwę miasta.
                    </mat-error>
                    <mat-error
                        *ngIf="city_name.value.length < cityNameMinLength && !city_name.errors?.['required']">
                        Minimalna długość wynosi {{ cityNameMinLength }} znaków.
                    </mat-error>
                    <mat-error *ngIf="city_name.value.length > cityNameMaxLength">
                        Maksymalna długość wynosi {{ cityNameMaxLength }} znaków.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mt-2 mx-2" style="width: 30%" appearance="outline">
                    <mat-label>Kod pocztowy:</mat-label>
                    <input matInput formControlName="zip_code" name="zip_code" type="text"
                           placeholder="Kod pocztowy" #zipCode>
                    <mat-hint align="end">
                        {{ zipCode.value.length }} / {{ zipCodeMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="zip_code.errors?.['required']">
                        Podaj kod pocztowy.
                    </mat-error>
                    <mat-error
                        *ngIf="zip_code.value.length < zipCodeMinLength && !zip_code.errors?.['required']">
                        Minimalna długość wynosi {{ zipCodeMinLength }} znaków.
                    </mat-error>
                    <mat-error *ngIf="zip_code.value.length > zipCodeMaxLength">
                        Maksymalna długość wynosi {{ zipCodeMaxLength }} znaków.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mt-2" style="width: 30%" appearance="outline">
                    <mat-label>Kod domofonu:</mat-label>
                    <input matInput formControlName="intercom_code" name="intercom_code" type="text"
                           placeholder="Kod domofonu" #intercomCode>
                    <mat-hint align="end">
                        {{ intercomCode.value.length }} / {{ intercomCodeMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="intercom_code.errors?.['required']">
                        Podaj kod domofonu.
                    </mat-error>
                    <mat-error
                        *ngIf="intercom_code.value.length < intercomCodeMinLength && !intercom_code.errors?.['required']">
                        Minimalna długość wynosi {{ intercomCodeMinLength }} znaków.
                    </mat-error>
                    <mat-error *ngIf="intercom_code.value.length > intercomCodeMaxLength">
                        Maksymalna długość wynosi {{ intercomCodeMaxLength }} znaków.
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </ng-template>
</div>
